
.order-card {
  color: #fff;
}

.bg-c-blue {
  background: linear-gradient(45deg,#4099ff,#73b4ff);
}

.bg-c-green {
  background: linear-gradient(45deg,#2ed8b6,#59e0c5);
}

.bg-c-yellow {
  background: linear-gradient(45deg,#FFB64D,#ffcb80);
}

.bg-c-pink {
  background: linear-gradient(45deg,#FF5370,#ff869a);
}


.card {
  border-radius: 5px;
  border: none;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.card .card-block {
  padding: 15px;
}

.order-card i {
  font-size: 16px;
}

.f-left {
  float: left;
}

.f-right {
  float: right;
}

.center-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
}

.border{
  border: 1px solid rgba(0,0,0,.125);
}

/*# sourceMappingURL=style.min.css.map */
